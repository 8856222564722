html,
body,
#root {
  height: 100%;
  margin: 0;
  margin: 0;
  padding: 0;
}

body {
  background: #efefef;
  font-family: Arial, Helvetica, sans-serif;
  color: #333;
}

/* Hides default select arrow in dropdowns https://stackoverflow.com/questions/16603979/how-to-remove-the-default-arrow-icon-from-a-dropdown-list-select-element */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none !important;
}

select::-ms-expand {
  display: none;
}

/* For Firefox */
select {
  text-indent: 0.01px;
  text-overflow: '';
}
/* Hides default select arrow in dropdowns */
